.appbtn {
  &-primary {
    background: #007bff;
    border-color: #007bff;
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #0048ff !important;
      background: #0048ff !important;
    }
  }
  &-secondary {
    background: #6c757d;
    border-color: #6c757d;
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #5d6d7b !important;
      background: #5d6d7b !important;
    }
  }
  &-success {
    background: #ff9933;
    border-color: #ff9933;
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #f78310 !important;
      background: #f78310 !important;
    }
  }
  &-danger {
    background: #dc3545;
    border-color: #dc3545;
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #c60519 !important;
      background: #c60519 !important;
    }
  }
  &-warning {
    background: #ffc107;
    border-color: #ffc107;
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #f77707 !important;
      background: #f77707 !important;
    }
  }
  &-info {
    background: #17a2b8;
    border-color: #17a2b8;
    color: #fff;

    &:hover,
    &:focus {
      color: #fff;
      border-color: #01a3bb !important;
      background: #01a3bb !important;
    }
  }
}
