// shared/styles/mixins.scss

// Media Query Mixin for Max-Width
@mixin max-w($breakpoint) {
  @media (max-width: $breakpoint) {
    @content;
  }
}

// Media Query Mixin for Min-Width
@mixin min-w($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}
