@import '../../sharedcomponents/styles/mixins.scss';
@import '../../sharedcomponents/styles/variables.scss';

.users {
  .can-access {
    @include max-w($d-sm) {
      width: 100% !important;
    }
  }
}
.dhc {
  &_account_state {
    width: 100px;
    border-radius: 15px;
    text-align: center;
    padding: 5px 0px;

    &_active {
      background-color: #ff9933;
    }
    &_pending {
      background-color: #17a2b8;
    }
  }

  &_icon_color {
    color: black;
  }
  &_email_inactive {
    color: #17a2b8;
  }
}
