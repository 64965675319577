@import '../../sharedcomponents/styles/mixins.scss';
@import '../../sharedcomponents/styles/variables.scss';

.devices {
  .id-item {
    display: flex;
    align-items: center;

    @include max-w($d-sm) {
      display: flex;
      flex-direction: column;
      align-items: start;
      width: 100% !important;

      &-label {
        width: 100% !important;
      }

      &-values {
        width: 100% !important;
        margin-left: 2px;
      }
    }
  }
}
